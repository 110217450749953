<template>
  <img src="../../assets/images/allnodepollbg.png" alt=""
    style="position: absolute;background-size: cover;width: 100%;z-index: 1;height: 100%;" />
  <div class="nodepoll">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold;font-size: 16px;line-height: normal;color: #fff;">節點大選</span>
          </div>
        </div>
      </template>
    </van-nav-bar>

    <div style="margin-left: 5%;margin-top: -7%;">
      <h4 style="font-size: 0.5rem;text-align: center;color: #fff;">請閣下投上寶貴的一票</h4>
      <h5 style="font-size: 0.5rem;color: #fff;">投票規則</h5>
      <p style="font-size: 0.36rem;font-weight: 500;color: #fff;margin-top: -5%;">
        • 全網持有Roc用戶均可投票，每投一票需消耗5枚Roc（單個地址可投N票）。<br />
        • 社區投票排名前47名為RoAc創世節點，排名後94名則為從節點。<br />
        • 首次投票截至時間為：28/11/2024—04/12/2024（GMT+8）。<br />
        • 往後每月28日統計票數，次月30日前獎勵到賬。<br />
        • 按排名與貢獻值權重分配獎勵。
      </p>
      <h5 style="font-size: 0.5rem;color: #fff;">獎勵規則</h5>
      <p style="font-size: 0.4rem;font-weight: 500;color: #fff;margin-top: -7%;">
        • 為了RoAc生態健康有序發展，平臺將Roc總發行量的30%作為節點人獎勵。<br />
        • 第一年35%節點獎勵<br />
        • 第二年30%節點獎勵<br />
        • 第三年20%節點獎勵<br />
        • 第四年10%節點獎勵<br />
        • 第五年05%節點獎勵</p>
      <h5 style="font-size: 0.5rem;color: #fff;">節點產生架構</h5>
      <p style="font-size: 0.4rem;font-weight: 500;color: #fff;margin-top: -7%;">• 47位創世節點+94位從節點+N位投票者</p>
      <h5 style="font-size: 0.5rem;color: #fff;">節點人獎勵分配</h5>
      <p style="font-size: 0.4rem;font-weight: 500;color: #fff;margin-top: -7%;">• 創世節點區50% （創世節點人25%，投票人75%）<br />
        • 從節點區50%（從節點人25%，投票人75%）</p>
        <!-- <van-swipe vertical :autoplay="3000" :show-indicators='false' :height="10" style="height: 35px;">
         <van-swipe-item v-for="(v, i) in tplist" :key="i">
           <span style="font-size: 0.3rem;color: #F1523D;">{{v.team_name}}社区获得壹票</span>
         </van-swipe-item>
        </van-swipe> -->
    </div>
    <!-- 列表開始 -->
    <div style="padding: 20px;background-color: #F4F5F7;display: flex;flex-wrap: wrap;">
      <van-row v-for="(item, index) in detailedlist" :key="index"
        style="flex-basis: 45%;margin-left: 2.5%;padding: 0% 2.5% 2.5% 0%;width: 45%;" @click="dialog()">
        <van-col span="24" style="padding: 20px;border-radius: 10px;background: #fff;position: relative;">
          <van-col style="position: absolute;right: 0%;top:0%">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 43 43" fill="none">
              <path
                d="M0.131592 0.817627H32.25C37.7729 0.817627 42.25 5.29478 42.25 10.8176V42.0101L19.7847 21.2563L0.131592 0.817627Z"
                v-if="index === 0 " fill="#F1523D" />
              <path
                d="M0.131592 0.817627H32.25C37.7729 0.817627 42.25 5.29478 42.25 10.8176V42.0101L19.7847 21.2563L0.131592 0.817627Z"
                v-else-if="index === 1 " fill="#F28436" />
              <path
                d="M0.131592 0.817627H32.25C37.7729 0.817627 42.25 5.29478 42.25 10.8176V42.0101L19.7847 21.2563L0.131592 0.817627Z"
                v-else-if="index === 2" fill="#FFBE1B" />
              <path
                d="M0.131592 0.817627H32.25C37.7729 0.817627 42.25 5.29478 42.25 10.8176V42.0101L19.7847 21.2563L0.131592 0.817627Z"
                v-else fill="#D5D3D3" />
              <text x="30" y="18" style="font-size: 0.35rem;" text-anchor="middle" dominant-baseline="middle"
                fill="#fff">{{ index + 1 }}</text>
            </svg>
          </van-col>
          <van-row>
            <van-col span="24"
              style="font-size: clamp(0.2rem, 0.3rem, 0.45rem);;font-weight: 550;text-align: center;">{{ item.team_name }}</van-col>
          </van-row>
          <van-row style="margin-top: 10px;">
            <van-col span="24"
              style="font-size: 12px;color: #9DA4B2;text-align: center;">{{item.dapp_address.substring(0, 3) + "****" + item.dapp_address.substr(item.dapp_address.length - 4)}}
            </van-col>
          </van-row>
          <van-row style="margin-top: 10px;">
            <van-col span="24"
              style="font-size: 0.4rem;color: #8A52F9;text-align: center;font-weight: 600;">{{item.user_count}}票</van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <!--投票彈框開始 -->
    <div v-if="show" style="margin-left: 15%;width: 100%;position:fixed;bottom: 0;">
      <img src="../../assets/images/faqbg.png" alt="" style="width: 70%;" />
      <div style="position: absolute;font-size: 0.4rem;width: 60%;margin-top: -77%;margin-left: 5%;">
        <h2 style="margin-left: 30%;">投票選項</h2>
        <h2 style="margin-left: 5%;font-size: 0.3rem;">即將消耗1次投票機會給以下社區投票</h2>
        <h2 style="margin-top: 25%;width: 100%;text-align: center;">{{tklist.team_name}}</h2>
      </div>
      <div @click="usenodeuserpoll"
        style="border-radius: 10px;background-color: #B791FA;width: 20%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-top: -11%;position: absolute;margin-left: 8%;">
        確認</div>
      <div @click="closePopup"
        style="border-radius: 10px;background-color: #B791FA;width: 30%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-left: 33%;margin-top: -11%;position: absolute;">
        考慮一會</div>
    </div>
    <!--投票弹框结束 -->
  </div>

</template>

<script setup>
  import {
    ref,
    reactive
  } from 'vue';
  import {
    getallnodePollList,
    getnodeuserPollList,
    getUserInfo,
    nodeuserpoll
  } from "@/utils/api.js";
  import {
    usdtabi
  } from '@/utils/abi.js'
  import Web3 from "@/utils/web3.min.js";
  import {
    useRouter
  } from "vue-router";
  import {
    precision
  } from '@/utils/precision.js';
  import {
    Toast
  } from 'vant';
  import {
    Notify
  } from 'vant';

  const chain_type = ref()
  const show = ref(false)
  const page = ref(1)
  const page_size = ref(50)
  const detailedlist = ref([])
  const time = ref([])
  const times = ref([])
  const text = ref()
  const onClickLeft = () => history.back();
  const router = useRouter();
  const add = ref('')
  const userinfo = ref();
  const userbalance = ref();
  const tklist = ref([])
  const tplist = ref([])
  // 获取用户信息
  async function getuser() {
    getnodeuserPollList().then((res) => {
      tplist.value = res.data;
      // console.log(userinfo);

    });
  }
  getuser();

  // 获取選舉列表
  async function getnodepolllist() {
    getallnodePollList().then((res) => {
      detailedlist.value = res.data;
      // console.log(detailedlist);

    });
  }
  getnodepolllist();


  function handleInput(event) {
    // 定义允许的字符范围
    const pattern = /^[a-zA-Z0-9]*$/;
    // 检查输入值是否包含非法字符
    const isValid = pattern.test(event.target.value);
    if (!isValid) {
      // 阻止输入事件
      event.preventDefault();
    }
  }

  function dialog() {
    Toast("結算中...");
  }

  function showPopup(item) {
    tklist.value = item
    show.value = true;

    // console.log('Selected item:', item);
    //   alert(`Selected item value: ${item.a_text}`);
    // console.log(item);
  }

  function closePopup() {
    show.value = false;
  }

  async function usenodeuserpoll() {

    const web3 = new Web3(window.ethereum);
    ethereum.enable()
    //获取地址
    let accounts = await web3.eth.getAccounts();
    let fromAddress = accounts[0];

    const a = await web3.eth.getGasPrice()

    //收R地址

    let toAddress = "0x83407455bAC363D0403A06afA3808dD77a599855";

    const shouqian = toAddress
    var Tx = {
      gas: 101323,
      gasPrice: a,
      from: fromAddress,
      value: 1 + '000000000000000000',
      to: shouqian,
    };
    web3.eth.sendTransaction(Tx)
      .on('receipt', function(receipt) {
        console.log("交易已确认，收据：", receipt);
        if (receipt !== null) {

          let form = {
            pollid: tklist.value.id,
          }
          nodeuserpoll(form).then((res) => {
            Toast(res.message)
            // onClickLeft()
          })
        } else {
          Toast({
            message: '链上拥堵',
            icon: 'success',
            className: 'noticeWidth'
          });
        }
      })


  }
</script>

<style lang="scss" scoped>
  .nodepoll {
    width: 100%;
    /* 设置div的宽度 */
    height: 100%;
    /* 设置div的高度 */
    // background-image: url('../../assets/images/allnodepollbg.png'); /* 背景图片的URL */
    // background-size: 100% 60%; /* 背景图片覆盖整个div */
    // background-position: center; /* 背景图片居中 */
    position: relative;
    z-index: 2;
    //  background-color: #F4F5F7;
  }

  input[type="radio"]:checked::after {
    background: #000;
  }

  .van-nav-bar__content {
    height: 1rem !important;
  }

  .butsty {
    height: 80px;
    font-size: 30px;
    padding: 30px;
    border-radius: 20px;

  }

  .noticeWidth {
    width: 30% !important;
    font-size: 32px !important;
  }

  ::v-deep .van-cell {
    padding: 50px !important;
  }

  ::v-deep .van-cell-group--inset {
    border-radius: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-weight: bold !important;
    font-size: 30px !important;
    height: 80px;
  }

  ::v-deep .van-nav-bar__content {
    padding-top: 30px;
    height: 80px;
    // margin: auto;
  }

  ::v-deep .van-nav-bar__arrow {
    font-size: 46px !important;
    color: #fff;
  }

  .van-dropdown-menu__bar {
    box-shadow: none !important;
  }

  .van-nav-bar__content {
    box-shadow: none !important;
  }

  .van-hairline--bottom:after {
    border-bottom-width: 0 !important;
  }

  .van-collapse-item {
    border: 1px red solid;
  }

  .van-popup {
    // position: absolute !important;
  }

  .van-dropdown-menu__title {
    line-height: normal !important;
  }

  .van-dropdown-menu__bar {
    height: 60px !important;
  }

  .van-nav-bar__left {
    padding: 0rem 0.3rem 0rem 0.3rem !important;
  }

  .van-ellipsis {
    font-weight: bold !important;
    overflow: visible;
  }
</style>
